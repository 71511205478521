// generic formio custom css starts
formio,
.formio,
form-builder,
.formio-component {
  font-size: 1.6rem;

  .form-control,
  .btn,
  div,
  a,
  span {
    font-size: 1.6rem;
  }
  .browse {
    color: $text-primary-100;
  }

  .card-title {
    font-size: 2rem;
    // font-family: "Tungsten" !important;
    // font-weight: 500;
  }

  .form-check-input {
    margin-top: 0;
  }

  .form-check-inline {
    margin-bottom: 1rem;
    .label-position-right {
      display: flex;
    }
  }

  .choices__item.choices__item--selectable {
    padding-right: 0px;
    // text-overflow: ;
    white-space: normal;
  }

  .choices__list--dropdown .choices__item--selectable,
  .choices__list[aria-expanded] .choices__item--selectable {
    padding-right: 10px;
  }

  .form-control {
    min-height: 36px;
    padding: 8px;
    border-color: none;
    background: #f0f0f0;
    box-shadow: none !important;
    caret-color: #010028;
    font-size: inherit;
    font-weight: 400;
    line-height: 1.125;
    font-family: Roboto, "Helvetica Neue", sans-serif;
    letter-spacing: normal;
    border: none;
  }

  .form-control:hover {
    background-color: #faf2ff;
  }

  #day-month.form-control option:nth-child(1) {
    background-color: #faf2ff;
  }

  #day-month.form-control option:nth-child(2) {
    background-color: #faf2ff;
  }

  #day-month.form-control option:nth-child(3) {
    background-color: #faf2ff;
  }

  #day-month.form-control option:nth-child(4) {
    background-color: #faf2ff;
  }

  #day-month.form-control option:nth-child(5) {
    background-color: #faf2ff;
  }

  #day-month.form-control option:nth-child(6) {
    background-color: #faf2ff;
  }

  #day-month.form-control option:nth-child(7) {
    background-color: #faf2ff;
  }

  #day-month.form-control option:nth-child(8) {
    background-color: #faf2ff;
  }

  #day-month.form-control option:nth-child(9) {
    background-color: #faf2ff;
  }

  #day-month.form-control option:nth-child(10) {
    background-color: #faf2ff;
  }

  #day-month.form-control option:nth-child(11) {
    background-color: #faf2ff;
  }

  #day-month.form-control option:nth-child(12) {
    background-color: #faf2ff;
  }

  #day-month.form-control option:nth-child(13) {
    background-color: #faf2ff;
  }

  #day-month.form-control option:nth-child(14) {
    background-color: #faf2ff;
  }

  .form-inline .form-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 0;
  }

  .form-text {
    font-size: 1.2rem;
    color: #737373;
  }

  .form-group > label {
    font-size: 1.2rem;
  }

  // .form-check-input {
  //   border-width: medium;
  //   border-color: $text-primary-100;
  // }

  .form-check-input:focus {
    border-color: $text-primary-100;
    /* default B5 color or set your own color*/
    outline: none !important;
    box-shadow: none !important;
  }

  .form-control:focus {
    border-bottom: 2px solid $text-primary-100;
    border-left: white;
    border-top: white;
    border-right: white;
    border-radius: 0px;
  }

  .formio-component-phoneNumber:focus-within label {
    color: $text-primary-100;
  }

  .formio-component-address:focus-within label {
    color: $text-primary-100;
  }

  .formio-component-currency:focus-within label {
    color: $text-primary-100;
  }

  //TEXTAREA
  .formio-component-textarea .form-control:focus {
    border-bottom: 2px solid $text-primary-100;
    border-left: white;
    border-top: white;
    border-right: white;
    border-radius: 0px;
  }

  .formio-component-textarea:focus-within label {
    color: $text-primary-100;
  }

  //PASSWORD
  .formio-component-password:focus-within label {
    color: $text-primary-100;
  }

  .formio-component-password .form-control:focus {
    border-bottom: 2px solid $text-primary-100;
    border-left: white;
    border-top: white;
    border-right: white;
    border-radius: 0px;
  }

  //RADIO BUTTON
  .formio-component-radio .form-check-input {
    position: relative;
    width: 19px;
    height: 18px;
    border-radius: 50%;
    border: 2px solid $text-primary-100;
    background-color: #fff;
    cursor: pointer;
  }

  .formio-component-radio .form-check-input:checked::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    right: 50%;
    bottom: 50%;
    border-radius: 50%;
    background-color: $text-primary-100;
    transform: translate(-50%, -50%);
    width: 0.6em;
    height: 0.6em;
  }

  .form-check-input[type="radio"]:checked {
    border: 2px solid $text-primary-100;
    outline: none;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    -webkit-appearance: none;
  }

  .formio-component-radio .form-check-input:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(134, 38, 195, 0.3);
  }

  //Label
  .bg-default {
    background-color: $bg-primary-100;
    color: white;
    // border-radius: 8px;
    font-weight: 600;
  }

  .border {
    border-radius: 8px;
  }

  .formio-component-textfield .form-control:focus {
    border-bottom: 2px solid $text-primary-100;
    border-left: white;
    border-top: white;
    border-right: white;
    border-radius: 0px;
  }

  .formio-component-textfield:focus-within label {
    color: $text-primary-100;
  }

  .formio-component-number:focus-within label {
    color: $text-primary-100;
  }

  .formio-component-number .form-control:focus {
    border-bottom: 2px solid $text-primary-100;
    border-left: white;
    border-top: white;
    border-right: white;
    border-radius: 0px;
  }

  .formio-component-dateTime .form-control:focus {
    border-bottom: 2px solid $text-primary-100;
    border-left: white;
    border-top: white;
    border-right: white;
    border-radius: 0px;
  }

  .formio-component-dateTime:focus-within label {
    color: $text-primary-100;
  }

  .formio-component-select .form-control:hover {
    border-bottom: 2px solid $text-primary-100;
    border-radius: 0px;
  }

  .formio-component-select:focus-within label {
    color: $text-primary-100;
  }

  .formio-component-checkbox,
  .formio-component-checkbox label,
  .formio-component-checkbox input {
    cursor: pointer;
  }

  select option {
    background-color: white;
  }

  .formio-component-checkbox input:hover {
    border-color: $text-primary-100;
  }

  .formio-component-button .btn {
    background-color: black;
    border-radius: 8px;
    border: 1px solid black;
  }

  .formio-component-button .btn:hover {
    background-color: $bg-primary-100;
    border: 1px solid $text-primary-100;
  }

  .formio-component-tags .choices__input .form-control {
    border: none;
    border-bottom: 2px solid $text-primary-100;
    border-radius: 0;
  }

  .formio-component-tags:focus-within label {
    color: $text-primary-100;
  }

  .formio-component-tags .choices__input .form-control:focus {
    /* Your styles for text fields here */
    border-bottom: 2px solid $text-primary-100;
    border-left: white;
    border-top: white;
    border-right: white;
    border-radius: 0px;
  }

  .formio-component-tags
    .choices__list--multiple
    .choices__item[data-deletable] {
    padding-right: 5px;
    border-radius: 10px;
    background-color: $bg-primary-100;
    color: white;
    border: none;
    box-shadow: 1px 1px #b5b3b3;
  }

  .formio-component-tags .choices[data-type*="text"] .choices__button {
    border-left: 1px solid white;
  }

  .formio-component-time .form-control:focus {
    border-bottom: 2px solid $text-primary-100;
    border-left: white;
    border-top: white;
    border-right: white;
    border-radius: 0px;
  }

  .form-check-input:hover {
    border-color: $text-primary-100;
  }

  .formio-component-time:focus-within label {
    color: $text-primary-100;
  }

  .formio-component-email .form-control:focus {
    border-bottom: 2px solid $text-primary-100;
    border-left: white;
    border-top: white;
    border-right: white;
    border-radius: 0px;
  }

  .formio-component-email:focus-within label {
    color: $text-primary-100;
  }

  .formio-component-url .form-control:focus {
    border-bottom: 2px solid $text-primary-100;
    border-left: white;
    border-top: white;
    border-right: white;
    border-radius: 0px;
  }

  .formio-component-url:focus-within label {
    color: $text-primary-100;
  }

  // FILE COMPONENT STARTS

  .formio-component-file {
    display: flex;
    flex-direction: column;
  }

  // .col-form-label{
  //   margin-left: 24px;
  // }
  // .text-muted{
  //   margin-left:24px;
  // }

  .formio-component-file .list-group-item {
    border: none !important;
  }
  .formio-component-file .list-group li:nth-child(1) {
    display: none;
  }

  .formio-component-file .list-group li:nth-child(2) {
    border-radius: 20px !important;
    padding: 20px !important;
    text-align: center !important;
    cursor: pointer;
    background-color: #f0f0f0 !important;
  }

  .formio-component-file .fileSelector {
    border: none !important;
    border-radius: 20px !important;
    padding: 20px !important;
    text-align: center !important;
    cursor: pointer;
    background-color: #f0f0f0 !important;
    transition: background-color 0.3s ease !important;
  }

  .formio-component-file .fileSelector:hover {
    background-color: #e0e0e0 !important;
  }

  .formio-component-file .fileSelector .fileSelector-icon {
    font-size: 48px !important;
    color: $text-primary-100 !important;
  }
  .formio-component-file .fileSelector .fa {
    color: $text-primary-100;
  }
  // FILE COMPONENT ENDS

  formio-alerts {
    display: none !important;
  }

  .formio-form {
    nav {
      display: none !important;
    }

    .formio-wizard-nav-container {
      li:nth-child(1) {
        display: none !important;
        button {
          color: #000000 !important;
          border: 1px solid #000000 !important;
          border-radius: 8px !important;
          font-weight: 600;
          font-family: "myriad-pro", "roboto", Arial, sans-serif !important;
          text-transform: uppercase !important;
          font-size: 1.8rem;
          font-weight: 600;
          padding: 11px 40px 10px !important;
          line-height: 16px !important;
          background: none !important;
        }
        button:hover {
          border: 1px solid #00b28f !important;
          color: #007c64 !important;
        }
      }
      li:nth-child(2) {
        margin-top: 16px !important;
        button {
          color: #000000 !important;
          border: 1px solid #000000 !important;
          font-weight: 600;
          font-family: "myriad-pro", "roboto", Arial, sans-serif !important;
          text-transform: uppercase !important;
          font-size: 1.8rem;
          font-weight: 600;
          padding: 11px 40px 10px !important;
          line-height: 16px !important;
          background: none !important;
        }
        button:hover {
          border: 1px solid #00b28f !important;
          color: #007c64 !important;
        }
      }
      li:nth-child(3) {
        display: none !important;
        button {
          color: #ffff !important;
          border: 1px solid #df9926 !important;
          border-radius: 8px !important;
          font-weight: 600;
          font-family: "myriad-pro", "roboto", Arial, sans-serif !important;
          text-transform: uppercase !important;
          font-size: 1.8rem;
          font-weight: 600;
          padding: 11px 40px 10px !important;
          line-height: 16px !important;
          background: #df9926 !important;
        }
        button:hover {
          background: #ffffff !important;
          border: 1px solid #000000 !important;
          color: #000000 !important;
        }
      }
    }
  }
}

// custom formio css starts


.download-button {
  height: 10%;
}

.vr {
  p {
    font-size: 1.2rem;
    color: red;
  }
}

@media (max-width: 575px) {
  .hd-custom {
    margin-top: 3rem;
  }
}

.formio-custom-dropdown {
  .choices {
    .choices__list {
      top: unset !important;
      bottom: unset !important;
      .choices__list {
        .choices__item.choices__item--selectable {
          text-overflow: unset;
        }
      }
    }
  }
}
.formio-custom-datagrid {
  table thead tr th:first-child {
    display: none;
  }
  table tbody tr td:first-child {
    display: none;
  }
}
@media (min-width: 0px) and (max-width: 767px) {
  .formio-custom-datagrid {
    overflow-y: hidden;
    table {
      display: block;
      overflow-x: auto;
      white-space: nowrap;
    }
    table thead tr th:first-child {
      display: none;
    }
    table tbody tr td:first-child {
      display: none;
    }
  }
}

.disabled {
  // pointer-events: none;
  ul {
    li:nth-child(2) {
      .row > div:first-child {
        display: none;
      }
    }
  }
}

.custom-chip {
  font-size: 1.4rem !important;
  min-height: 26px;
  padding: 6px;
  margin-bottom: 5px;
}
.custom-msg {
  font-size: 1.4rem !important;
  color: #d2401d;
}
.tab_red {
  background-color: #fde7e9;
  color: #d2401d;
}
.tab_green {
  background-color: #dff6dd;
  color: #37c3a2;
}
.tab_purple {
  background-color: #edcfff;
  color: #5e1f89;
}
.tab_yellow {
  background-color: #fff4ce;
  color: #ad6f21;
}
.tab_blue {
  background: #deebff;
  color: #3367d6;
}
.tab_grey {
  background: #efefef;
  color: #7e7e7e;
}
.d-flx {
  display: flex;
  justify-content: space-between;
}

.chip {
  display: inline-flex;
  align-items: center;
  background-color: #e0e0e0;
  padding: 4px 8px;
  border-radius: 16px;
  margin-right: 8px;
  font-size: 14px;
}

.file-name {
  margin-bottom: 5px;
  font-size: 1.4rem !important;
  padding: 8px 12px;
  cursor: pointer;
}
.save-btn {
  margin-left: 0.5rem;
}

.red {
  color: red;
}

.form-font-info {
  font-size: 1.4rem !important;
}

.form-font-label {
  font-size: 1.6rem !important;
  font-weight: bold;
}

.dynamic-para{
  font-weight: bold;
  line-height: 1 !important;
}

.download{
  z-index: 10;
  justify-content: flex-end;
}


.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.spinner {
  width: 18px;
  height: 18px;
  border: 3px solid #00b28f;
  border-top-color: transparent;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.gap{
  gap:6px;
}

.white-space{
  white-space: pre-line;
}