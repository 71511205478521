/* You can add global styles to this file, and also import other style files */
@import "bootstrap/scss/bootstrap";
@import "../node_modules/ag-grid-community/src/styles/ag-grid.scss";
@import "../node_modules/ag-grid-community/src/styles/ag-theme-alpine/sass/ag-theme-alpine-mixin.scss";

/*custom Sass*/
@import "saas/variables";

@import "saas/mixins";
@import "saas/media_query";

/*plugin css*/
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";

@import "saas/main.scss";

/*custom css*/
@import "saas/customBootstrap.scss";
@import "saas/allComponents.scss";
@import "saas/web_allComponents.scss";
@import "saas/iconStyles.scss";
@import "saas/utility.scss";
@import "assets/fonts/DS-iconfont.css";
@import "saas/formio-custom.scss";

@import "@material/tab-bar/dist/mdc.tab-bar.css";
@import "@material/tab-scroller/dist/mdc.tab-scroller.css";
@import "@material/tab-indicator/dist/mdc.tab-indicator.css";
@import "@material/tab/dist/mdc.tab.css";

/*Ag Grid*/
@import "~ag-grid-community/dist/styles/ag-grid.css";
@import "~ag-grid-community/dist/styles/ag-theme-balham.css";

@import "~gridstack/dist/gridstack.min.css";
@import "~gridstack/dist/gridstack-extra.min.css";

html,
body {
  height: 100%;
}

.font-oswald {
  font-family: "Oswald", "Roboto", Arial;
}

.font-secondary {
  font-family: "myriad-pro" !important;
}

// ? use DSA_wb_primary-btn text-uppercase class in place of this
.primary-btn {
  min-width: 164px;
  height: 40px;
  text-align: center;
  font-size: 14px;
  line-height: 21px;
  background: no-repeat padding-box #df9926;
  letter-spacing: 2.52px;
  color: #fff;
  text-transform: uppercase;
  border: 1px solid #df9926;
  padding: 0 20px;
  cursor: pointer;

  &:hover:enabled {
    background: no-repeat padding-box #fff;
    border: 1px solid #000;
    color: #000 !important;

    span.buttonText {
      color: #000 !important;
    }
  }

  &:disabled {
    cursor: default;
    opacity: 0.6;
  }
}

.secondary-btn {
  border: 1px solid #000;
  background: #fff;
  color: #000 !important;
  height: 40px;
  text-align: center;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 2.52px;
  text-transform: uppercase;
  padding: 0 20px;
  cursor: pointer;
}

.black-btn {
  background: #000000;
  border: 1px solid #000000;
  color: #fafafa;
  height: 40px;
  text-align: center;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 2.52px;
  text-transform: uppercase;
  padding: 0 20px;
  cursor: pointer;

  &:disabled {
    cursor: default;
    opacity: 0.2;
  }
}

.mustard-icon {
  background: #df9926;

  i {
    color: #fff;
  }
}

.container-box-shadow {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  padding: 20px;
}

.ag-theme-balham .ag-root-wrapper {
  border: none;
}

.ag-row-hover {
  background-color: #6e42c12f !important;
  border-radius: 10px;
  box-shadow: inset 1px -1px 6px #00000049 !important;
}
.ag-cell-wrapper {
  height: 100%;
}
.ag-cell {
  border: none !important;
  font-weight: 400;
  font-style: normal;
  color: #000000;
  padding: 11px;
  vertical-align: middle;
}
.ag-header-cell,
.ag-header-group-cell {
  background-color: #ffffff;
  border: none !important;
  font-weight: bold;
  color: #000000;
}

.ag-theme-alpine {
  @include ag-theme-alpine(
    (
      odd-row-background-color: #cfd8dc,
    )
  );
}
ag-grid-angular {
  height: 100%;
}

.mat-form-field-label {
  font-family: "myriad-pro" !important;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: 0px;
  margin-left: 0px;
}

.br-square .br-units {
  counter-reset: counter;
}
.br-square .br-unit {
  width: 30px;
  height: 30px;
  border: 2px solid red;
  background-color: white;
  margin: 2px;
  text-decoration: none;
  font-size: 14px;
  line-height: 2;
  text-align: center;
  color: red;
  font-weight: 600;
}
.br-square .br-unit.br-active,
.br-square .br-unit.br-selected {
  border: 2px solid #4278f5;
  color: #4278f5;
}
.br-square .br-unit:after {
  content: counter(counter);
  counter-increment: counter;
}
.br-square.br-readonly .br-active,
.br-square.br-readonly .br-selected {
  border: 2px solid #729bf8;
  color: #729bf8;
}
.br-square.br-readonly .br-theme {
  color: #f2cd95;
}
.font-white {
  color: white;
}
.layout {
  margin-top: 72px;
}
.purple-snackbar {
  background: #8626c3;
}
.green-snackbar {
  background: #00b28f;
}

.success-snackbar {
  // background: $bgcolor-dark !important;
  font-size: larger;
  color: $text-black;
  background-color: $bg-status-success;
  border: $border-width-1 solid rgba(0, 122, 82, 0.5);
  max-width: 800px;
  margin: 0 auto;
  border-radius: $border-radius-big;
}

.success-snackbar .mat-simple-snackbar-action {
  color: $text-black;
}

.warning-snackbar {
  // background: $bgcolor-dark !important;
  font-size: larger;
  color: $text-black;
  background-color: orange;
  border: $border-width-1 solid rgba(233, 122, 11, 0.737);
  max-width: 800px;
  margin: 0 auto;
  border-radius: $border-radius-big;
}
.warning-snackbar .mat-simple-snackbar-action {
  color: $text-black;
}

.error-snackbar {
  // background: $bgcolor-dark !important;
  font-size: larger;
  // color: white;
  color: $text-black;
  background-color: $bg-status-error;
  border: $border-width-1 solid rgba(204, 41, 0, 0.5);
  max-width: 800px;
  margin: 0 auto;
  border-radius: $border-radius-big;
}
.error-snackbar .mat-simple-snackbar-action {
  color: $text-black;
}
.red-snackbar {
  background: #d62d46;
}

.timer-snackbar {
  min-width: 0 !important;
  min-height: 0 !important;
  max-width: 90% !important;
  padding: 0 !important;
  background: none !important;
}
.pointer {
  cursor: pointer;
}
.overflowx {
  overflow-x: auto;
}
.font-red {
  color: red;
}

.internal-style {
  table,
  td,
  th {
    border: 1px solid black;
  }
}

.internal-style {
  table {
    width: 100%;
    border-collapse: collapse;
  }
}

.mat-expansion-panel-body {
  padding: 0 0 0 0 !important;
}

.filter-criteria {
  .interviewStatus {
    background-color: #b174d9 !important;
  }

  .unit {
    background-color: #9e51cf !important;
  }

  .spoc {
    background-color: #8626c3 !important;
  }

  .jobRole {
    background-color: #6b1e9c !important;
  }

  .technology,
  .platform {
    background-color: #5e1f89 !important;
  }

  .interviewResult {
    background-color: #4b196e !important;
  }

  .finalResult {
    background-color: #3c1458 !important;
  }
}

.filter-panel .mat-dialog-container {
  width: 80vw;

  .layout {
    margin-top: 0;
  }
}

.add-jobs,
.add-panelists,
.add-recruiters {
  .mat-dialog-container {
    width: 500px;

    .layout {
      margin-top: 0;
    }
  }
}

.backdrop-background {
  background-color: rgba(#000, 0.65);
  backdrop-filter: blur(1px);
}
.blur-backdrop {
  background-color: rgba(#000, 0.65);
  backdrop-filter: blur(5px);
}

.no-data-img {
  height: 150px;
}

.filter-criteria {
  .mat-chip.mat-standard-chip.DSA_wb_chipStyle-Normal {
    color: #fff;
    height: 28px;
    padding: 0px 14px;
    line-height: 28px;

    &:hover {
      border: none;
    }
  }

  .mat-chip.mat-standard-chip.DSA_wb_chipStyle-Normal:not(.DSA_wb_chip-input):focus {
    color: #fff;
  }

  .mat-chip.mat-standard-chip.DSA_wb_chipStyle-Normal .icon-close-mini {
    color: #fff;
    padding: 0 3px;
  }
}

::ng-deep .formio-question-card {
  background-color: #fff;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.16) !important;
  border-radius: 10px;
  padding: 20px;
}

.toast-custom {
  background-color: #8626c3 !important;
  padding: 14px 16px !important;
  font-size: 14px !important;
  color: rgba(255, 255, 255, 0.7) !important;
}
.toast-success {
  background-color: $bg-status-success;
  padding: 14px 16px !important;
  font-size: 14px !important;
  color: rgba(255, 255, 255, 0.7) !important;
}
.toast-error {
  background-color: $bg-status-error;
  padding: 14px 16px !important;
  font-size: 14px !important;
  color: rgba(255, 255, 255, 0.7) !important;
}

.dialog-bg .mat-dialog-container {
  padding: 0px !important;
}

.mat-horizontal-content-container {
  padding: 0 !important;
}

@media only screen and (max-width: 768px) {
  .DSA_wb_primary-btn {
    width: 100%;
    padding: 0px !important;
    height: 40px !important;
  }
  .secondary-btn {
    width: 100%;
    height: 40px !important;
  }
}

.DSA_wb_customTab .mat-tab-label,
.DSA_wb_customTab .mat-tab-link {
  height: 40px;
}

.jodit-container {
  ul,li {
    padding: revert;
    margin: revert;
    list-style: revert;
  }
}
