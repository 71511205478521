@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/materialicons/v48/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format('woff2');
  }
  html {
    height: 100%;
    font-size: $basefont-size;
    }
  body {
    min-height: 100%;
      background: $bg-white;
      font-family: 'roboto', sans-serif !important;
      margin: 0;
      padding: 0;
      font-size: $baseBodyfont-size;
  }

  h1, h2, h3, h4, h5, h6, p{
      margin: 0;
      padding: 0;
  }
  ul, li{
      list-style: none;
      padding: 0;
      margin: 0;
  }  

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;  
  /*src: url(assets\fonts\materialFont.woff2) format('woff2');*/
  src: url("/src/assets/fonts/materialFont.eot"); /* IE9 Compat Modes */
  src: url("/src/assets/fonts/materialFont.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("/src/assets/fonts/materialFont.otf") format("opentype"), /* Open Type Font */
    url("/src/assets/fonts/materialFont.svg") format("svg"), /* Legacy iOS */
    url("/src/assets/fonts/materialFont.ttf") format("truetype"), /* Safari, Android, iOS */
    url("/src/assets/fonts/materialFont.woff") format("woff"), /* Modern Browsers */
    url("/src/assets/fonts/materialFont.woff2") format("woff2"); /* Modern Browsers */ 
}  

.material-icons {
  font-family: 'Material Icons', Helvetica, Arial, Verdana, Tahoma, sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  font-feature-settings: 'liga';
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}
.ds_container{
  width:100%;
  padding:0px 16px;
}
.ds_mainHeaderCtr{
    background: $bg-primary-80;   
    line-height: 20px;
    /*font-size: 20px;*/
    font-weight: 700;
    position: fixed;
    width: 100%;
    
    z-index: 999;
    left: 0;
    top: 0;
    right: 0;
}
.DSA_stickyHeader {
    @extend %header-shadow;
}
.ds_mainHeader{
  padding:0px;
  height:56px;
}
@media screen and (min-width:768px){
  .fixedNav{
    position: fixed;
  }
}
@media screen and (min-width:768px) and (max-width:1279px){
  .fixedNav{
    max-width: 200px;
  }
}
.ds_leftNav{
  width: 240px;
  height: 100%;
  position: fixed;    
  left: 0;  
  padding: 24px !important;    
  box-shadow: 4px 0px 8px 0px rgba(0,0,0,0.14);
  background: $bg-white;
  z-index: 1;
}
.ds_leftNav mat-icon{
  float:right;
  margin-top: 6px;
}
.ds_leftNav_h3{
  line-height: 40px;
  padding:0px 16px;
  margin:0;
  font-size: 16px; 
  border-bottom:1px solid $bg-white;  
  font-weight:500;      
}
.ds_collapse{
background: $bg-neutral-40 url('/src/assets/images/icon-collapse-arrow-dark.png') right 16px top 50% no-repeat;
}
.ds_expand{
background: $bg-neutral-40 url('/src/assets/images/icon-expand-arrow-dark.png') right 16px top 50% no-repeat;
}
.ds_leftNav_h4{
  background:$bg-white;
  line-height: 40px;
  padding:0px 16px;
  margin:0;
  font-size: 16px;  
  font-weight:normal;  

}
.ds_leftNav_h4.ds_collapse{
background: $bg-white url('/src/assets/images/icon-collapse-arrow-dark.png') right 16px top 50% no-repeat;
}
.ds_leftNav_h4.ds_expand{
background: $bg-white url('/src/assets/images/icon-expand-arrow-dark.png') right 16px top 50% no-repeat;
}
.ds_secMenu{
  border-top:1px solid $bg-neutral-40;   
  border-bottom:1px solid $bg-neutral-40; 
}

ul.dc_TreeulClass{
  list-style: none;
  margin:0;
  padding:0    
}
ul.dc_TreeulClass>li{
  margin:0;
  padding:0;
}
ul.dc_TreeulClass>li>a{
  color:$text-neutral-80;
  line-height: 16px;
  padding:12px 0px;
  display: inline-block;
  text-decoration: none;
  font-size: 14px;
  cursor: pointer;
}
ul.dc_TreeulClass>li>a:hover{
  color:$text-black;
}
ul.dc_TreeulClass>li>a.active{
  font-weight: bold;
  color: $text-primary-80;
}
ul.dc_TreeulClass>li>a.active:hover{
  color:$text-primary-80;
}
.ds_h2Menu{
  font-size:16px;
  color:$text-black;
  margin:0 0px 16px 0;
  font-weight: 500;
 }
 .ds_h2Menu:first-child{
     margin-top: 0;
 }

ul.dc_TreeulClass > li > .dc_SubMenu{
  padding-left: 20px!important;

  li{
      a.active{
          color: $text-primary-80;
          position: relative;

          &:before{
              width: 4px;
              height: 4px;
              border-radius: 50%;
              background: $bg-primary-80;
              position: absolute;
              left: -10px;
              top: 18px;
              content: ''
          }
      }
  }
}

.ds_RightCnt{
  padding:16px 0px;
  margin: 0 0 0 240px;  
}
.list-withBullets {
  list-style: disc;
  margin: 0;
  margin-top: 10px;
}
.list-withBullets li {
  font-size: 14px;
  font-weight: 400;
  color: $text-black;
  list-style: disc;
  margin: 0 0 0 15px;
  line-height: 25px;
}
.numberedli li{
  list-style: decimal !important;
}
.lowerAlphaLi li{
  list-style: lower-alpha !important;
}
a.ds_logo,a.ds_logo:hover,a.ds_logo:focus,a.ds_logo:active{
    color:$text-white;
    text-decoration: none;
    margin: 16px 0 12px 0;
   
}
body{
    background: $bg-neutral-40;
    font-family: 'roboto', sans-serif !important;
}
.ds_headerLftmar{
  margin-left: 80px;
}
.ds_mainMenu{
  border-bottom:1px solid $bg-neutral-50;
  background: $bg-white;
  
}
a.ds_Download,.ds_Download{
  color:$text-white;
  display: inline-block;
  font-size: $size-text-16;
  margin-top: 12px;
  line-height: 32px;
}
.ds_Download a{
  color:$text-white;
  text-decoration: underline;
  
}
ul.ds_mainMenuUl{
  margin:0;
  padding: 0;
}
.ds_mainMenuUl li{
  float: left;
  list-style: none;
 

}
.ds_mainMenuUl li button{
border:0;
line-height: 40px;
font-size: 14px;
padding: 0px 25px 0px 16px;
margin-right: 16px;
background: url('/src/assets/images/icon-expand-arrow-dark.png') 100% 50% no-repeat;
}
a.ds_allMenu,a.ds_allMenu:hover{
  background: none;
  border:0;
  line-height: 40px;
  font-size: 14px;
  padding: 0;
  margin-right: 16px;
  color:$text-black;
  text-decoration: none;
  font-weight: 400;
}

.ds_firstLevelmenu .mat-menu-item,
.ds_secondLevelmenu .mat-menu-item{
  height:40px !important;
  line-height: 40px!important;
}
.ds_firstLevelmenu .mat-menu-content:not(:empty),
.ds_secondLevelmenu .mat-menu-content:not(:empty){
  padding: 0 !important;
}
.ds_firstLevelmenu,.ds_secondLevelmenu, .mat-menu-panel{
  min-height: 40px!important;
}
.ds_firstLevelmenu button:focus,
.ds_secondLevelmenu button:focus,
.ds_mainMenuUl li button:focus{
  border:0;
  box-shadow:none;
  outline:none;
}
.mobilemenuCtr a.ds_allMenu{
  padding:0px 25px 0px 16px;
}
.mobilemenuCtr ul.ds_mainMenuUl{
width:250px;
}
.mobilemenuCtr .ds_mainMenuUl li{
  float: none;
  display: block;

}
.mobilemenuCtr .ds_mainMenuUl li button{
  display: block;
  float: none;
  width: 100%;
  text-align: left;
}

.ds_firstLevelmenu .mat-menu-item-submenu-trigger::after {
  width: 24px;
  height: 24px;
  border-style: solid;
  border-width: 0;
  content: '';
  display: inline-block;
  position: absolute;
  top: 55%;
  right: 16px;
  transform: translateY(-50%);
  background: url(/src/assets\images\btn-icon-next-dark.png) 100% 50% no-repeat;
}

.ds_firstLevelmenu .ds_singleElement.mat-menu-item-submenu-trigger::after {
  background: none;
}

.ds_mainContent{
  padding-top: 97px;
  padding-bottom: 40px;
}
.ds_mainContent.ds_withoutmenu{
  padding-top: 56px;
  padding-bottom: 0;
}
@media screen and (max-width:767px){
  .ds_mainContent{
    padding-top: 50px;
  }

}


.ds_matcard{
  padding:0 !important;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(168,168,168,0.5);
  -moz-box-shadow: 0px 0px 5px 0px rgba(168,168,168,0.5);
  box-shadow: 0px 0px 5px 0px rgba(168,168,168,0.5);
}
.ds_matcard:not([class*=mat-elevation-z]){
  -webkit-box-shadow: 0px 0px 5px 0px rgba(168,168,168,0.5);
  -moz-box-shadow: 0px 0px 5px 0px rgba(168,168,168,0.5);
  box-shadow: 0px 0px 5px 0px rgba(168,168,168,0.5);
}

.ds_matcard .mat-card-header-text{
margin:0;
}
.ds_matcard>.mat-card-header{
  height:60px;
  padding:16px;
  background: $bg-neutral-40;
  color:$text-black;
  font-size:14px;
  border-bottom:0;
}
.ds_matcard>.mat-card-header .mat-card-title{
  font-size: 16px;
  margin-bottom: 0;
  line-height: 30px;
 }
.ds_matcard>.mat-card-content{
  padding:16px;
}
.ds_absRight{
  position: absolute;
  right:20px;
  top:34px;
  z-index: 999;
}
.ds_linkBtn{
  position: absolute;
  left:0px;
  top:-8px;
  z-index: 999;
  margin-right: 16px !important;
}
.ds_absRight a{
  color:$text-neutral-80;
  background: $bg-neutral-40;
}
.ds_absRight a.active{
  color:$text-primary-80 !important;
}
.ds_marRght16{
  margin-right: 16px;
}

a.ds_copyIcon{
  color:$text-neutral-80;
  font-size: 18px;
  text-decoration: none;
}
a.ds_copyIcon:hover,a.ds_copyIcon:active{
  text-decoration: none;
}

.ds_selectedTxt{
  color:$text-status-informtion;
}

.ds_h2{
  font-size: 20px;
  line-height: 8px;
  padding: 24px 90px 24px 8px;
  margin: 20px 0px;
  background: $bg-neutral-40;
  border-bottom:1px solid $bg-neutral-50;
}


.ds_h1Inner{
  margin:16px 0px 16px 0px;
  font-size: 34px;
  font-weight:400;
  color:$text-neutral-80;
  }
  
  .ds_h3Inner{
    margin:24px 0px 40px 0px;
    font-size: 20px;
    font-weight:400;
    color:$text-neutral-80;
  
  }
  .ds_h4Inner{
    margin:24px 0px 24px 0px;
    font-size: 18px;
    font-weight:400;
    color:$text-neutral-80;
  
  }
  .ds_h5Inner{
    margin:24px 0px 24px 0px;
    font-size: 16px;
    font-weight:bold;
    color:$text-neutral-80;
  
  }

  .ds_h5hdr{
    margin:0;
    font-size: 16px;
    font-weight:bold;
    color:$text-black;
  
  }
.ds_hr{
  margin:40px 0px;
  border: 0;
  border-bottom:1px solid $bg-neutral-50;
}
.ds_paddInnerDiv>div{
  margin-bottom: 10px;
  padding: 5px; 

}
.ds_shadowbox{
  height: 50px;
  margin-bottom: 20px !important;
  padding: 10px;
}

.ds_sticky{
  @extend %header-shadow;
}
.ds_RightCnt{
    padding:40px !important;
    background: $bg-neutral-30;
    
}
.ds_RightCnt .card{
    border:0;
  }  

.ds_mainFooter{
    width: 100%;
    height: 40px;
    background: $bg-black;
}
.ds_tabPad{
    padding:24px 2px;
   
}
.ds_marTop24px{
    margin-top:24px;
    }
pre code{
    color:$bg-neutral-80 !important;
}
.ds_marRght16{
    margin-right: 16px;
}
.ds_h2Menu{
  font-size:16px;
  color:$text-black;
  margin:0 0px 16px 0;
  font-weight: 500;
 }
 .ds_h2Menu:first-child{
     margin-top: 0;
 }

.ds_h2{
font-size:32px;
color:$text-black;
margin:16px 0px;
font-weight: 500;
}
.ds_h3{
  font-size:17px;
  color:$text-black;
  margin:16px 0px;
  font-weight: 500;
  
}
.ds_h4,.ds_h4span{
  font-size:14px;
  color:$text-black;
  margin:16px 0px;
  font-weight: 500;
}
.ds_h4span{
  margin:0;
}
.ds_p{
  font-size:14px;
  color:$text-neutral-80;
  margin-bottom:8px;
  font-weight: 400;
}
.ds_ul{
  position: fixed;    
  margin-top:20px;
  width:17%;
}
.ds_ul li{    
 
  list-style: none;
  
 
}
.ds_ul li a{
  color:$text-black;
  padding:8px 16px;
  display: block;
  font-size:15px;   
  line-height: 20px;
  border-left:3px solid $bg-neutral-40;
  border-bottom:1px solid $bg-neutral-40;
  font-weight: normal;
  text-decoration: none;
}
.ds_ul li a:hover{
  border-left:3px solid $bg-primary-80;
}
.ds_ul li:last-child a{
  border-bottom:0;
}

.ds_hr{
  border:0;
  border-bottom:1px solid $bg-neutral-40;
}

.ds_download{    
  display: inline-block;
}
.ds-full-width{
  width:100%;
}
.ds_pUl{
  color:$text-neutral-80;
  padding-left: 15px;
}
.ds_absRight a{
  color:$text-neutral-80;
}
.ds_absRight a.active{
  color:$text-primary-80;
}
pre.ds_pre{
    word-wrap: break-word;
    white-space: pre-wrap;
    line-height: 15px;
    padding:0;
    margin:0;
    overflow: hidden;
}
a.ds_copyIcon{
    color:$text-neutral-80;
    font-size: 18px;
    text-decoration: none;
}
a.ds_copyIcon:hover,a.ds_copyIcon:active{
    text-decoration: none;
}
.clearboth{
  clear:both;
}
.ds_selectedTxt{
  color:$text-status-informtion;
}
.ds_pOl{
  list-style: decimal;
}
.ds_alphabet{
  list-style: lower-alpha;
}

.ds_menuScroll{
    position: relative;    
    height: calc(100% - 80px);
}


.ds_menuUl{
    list-style: none;
    margin: 0 0 0 60px;
    padding: 0;
    li{
        float: left;
        margin: 0 0 0 32px;
        a{
            color: $text-white;
            opacity: 0.5;
            text-transform: uppercase;
            font-size: $size-text-14;
            font-weight: 400;
            line-height: 56px;
        }
        a:hover, a:focus{
            text-decoration: none;
            opacity: 1;
        }
        a.active{
            opacity: 1;
            padding: 0 0 16px;
            border-bottom: 4px solid $bg-white;
            font-weight: 500;
        }
    }
    
}

ul.dc_TreeulClass > li > .dc_SubMenu{
  padding-left: 20px!important;

  li{
      a.active{
          color: $text-primary-80;
          position: relative;

          &:before{
              width: 4px;
              height: 4px;
              border-radius: 50%;
              background: $bg-primary-80;
              position: absolute;
              left: -10px;
              top: 18px;
              content: ''
          }
      }
  }
}
.ds_section{
    padding: 24px 0  40px 0;
}
.ds_tabsection{
  padding-top:16px;
}
.list-withBullets{
  list-style: disc;
  margin: 0;
  li{
      font-size: $size-text-14;
      font-weight: $font-400;
      color: $text-black;
      list-style: disc;
      margin: 0 0 0 15px;
        line-height: 25px;
        ul{
            margin: 0 0 0 15px;           
        }
        .example-wrap{
            ul{
                margin: 0;
                li{
                    list-style: none;
                    margin: 0;
                }
            }
        }
    }
}
hr.separator_hr{
    border-top: 1px solid $bg-neutral-50;
    margin: 0;
    margin-bottom: 16px;
}


.guidelines-carousel-wrap{
  border:1px solid $bg-neutral-50;
  border-radius: 4px;
  padding: 20px;
  background: $bg-white;
}

.cr-table{
    display: flex;
    width: 100%;
    align-items: center;

    .cr-image{
        padding-right: 0!important;
    }    
}
.cr-table > div{
    padding: 0 20px;
}

.guidelines-carousel-wrap {
  .cr-table{
    min-height: 450px;
  }
  
  .owl-carousel .owl-item img{
      width: auto;
  }

  p{
      font-size: 24px;
      color: $text-black;
  }

  .owl-nav,.owl-dots{
      text-align: right;
  }

    .owl-prev{
        margin-right: 350px!important;
        background: url(/src/assets/images/caurosel/icon-previous.png) no-repeat left center!important;
        padding-left: 30px!important;
    }

    .owl-next{
        background: url(/src/assets/images/caurosel/icon-next.png) no-repeat right center!important;
        padding-right: 30px!important;
    }
    .owl-next:hover{
      background: url(/src/assets/images/caurosel/icon-nextHover.png) no-repeat right center!important;
      color:$text-black!important;
    }
    .owl-prev:hover{
      background: url(/src/assets/images/caurosel/icon-previousHover.png) no-repeat left center!important;
      color:$text-black!important;
  }

    .owl-dots{
        margin: -25px 150px 0 0
    }

  .owl-theme .owl-nav [class*=owl-]{
      background: none;
      color: $text-primary-80;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 500;   
  }

  .owl-theme .owl-dots .owl-dot span{        
      background: none;
      border:1px solid $bg-primary-80;
  }
  .owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span{
      background: $bg-primary-80;
  }

  .owl-theme .owl-nav {
      .disabled{
      color: $text-neutral-50!important;
      opacity: 1;
      }

        .owl-prev.disabled{
            background: url(/src/assets/images/caurosel/icon-previous-default.png) no-repeat left center!important;
        }
        .owl-next.disabled{
            background: url(/src/assets/images/caurosel/icon-next-default.png) no-repeat right center!important;
        }
  }
    ol.ds_orderedlist{
        margin: 0 0 0 15px;
        padding: 0;
        float: none;
    }
    .list-withBullets{        
        li{
            font-size: $size-hd-18;            
        }
    }
}
.systemMsgs-carousel{
    .owl-prev{
        margin-right: 700px!important;
    }
}
.tabs-carousel{
    .owl-prev{
        margin-right: 450px!important;
    }
}
.ds_box{
    padding: 40px;
    border: 1px solid $bg-neutral-50;
    border-radius: 4px;
    margin: 16px 0 0;
    background: $bg-white;
}
.ds_box.box-2Row{
  padding: 0;
}
.row-grey-bg{
  padding: 40px;
  background: $bg-neutral-30;
}
.row-white-bg{
  padding: 40px;
}
.box-text{
  font-size: 24px;
  font-weight: 400;
  color: $text-black;
  line-height: 56px;
  margin: 0 0 0 80px;
  float: left;
}
ol.ds_orderedlist{
    list-style: decimal;
    margin: 20px 0 0 80px;
    float: left;
    li{
        list-style: decimal;
        font-size: $size-hd-18;
        font-weight: $font-400;
        color: $text-black;
        margin: 0 0 16px 10px;        
        span{
        font-size: 14px;
        color: $text-neutral-80;
        display: block;
        margin-top:8px;
        }
        div{
            font-size: 14px;
            color: $text-neutral-80;
            margin-top:8px;
        }
    }
}
.row-margin{
  margin: 0 0 40px;
}
.row-white-bg{
  .box-text{
      margin: 0 0 0 125px;
  }
}
.txt-margin{
  margin: 40px 0 8px;
}
.ds_rightIcons{
  margin: 14px 0 0;
  li{
      float: left;
      margin: 0 0 0 20px;
      a{
          display: inline-block;
      }
  }
}

.dataTbl{
  width:100%;
  @extend %mb-default-shadow;
}
.dataTbl th{
  background: $bg-neutral-30;
  color:$text-black;
  padding:10px 15px;
  line-height: 15px;
  font-weight: 500;
}
.dataTbl td{
  background: $bg-white;
  color:$text-black;
  padding:10px 15px;
  line-height: 15px;
  border-bottom:1px solid $bg-neutral-30;
}

.ds_rightArrow{
  width: 0; 
  height: 0; 
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;  
  border-left: 10px solid $bg-neutral-50;
  display: inline-block;
  margin-top: 30px;
}
.ds_docImg{
  margin-top:60px;
  margin-bottom:60px;
}
.ds_docImg img{
  width:100%;
}
.ds_docImg img.smallImg{
  width:80%;
}
.ds_views{
margin-top: 12px;
}
.ds_views a,.ds_views a:hover,.ds_views a:focus{
  color:$text-white !important;
  text-align: center;
  float: left;
  vertical-align: middle;
  height: 25px;
}
.ds_views a i{
  font-size: 30px;
  height:30px;  
  text-decoration: none;
  opacity:0.5;
  float: left;
  vertical-align: middle;
}
.ds_views a strong{
  font-size:14px;
  color:$text-white;
  font-weight:400;
  opacity:0.5;
  vertical-align: middle;
  line-height: 30px;
  display: inline-block;
  margin-left: 10px;
}
.ds_views a strong.margin1{
  margin-left: 5px;
}
.ds_views a:hover,.ds_views a:hover strong,.ds_views a:hover i{
  opacity:1;
  text-decoration: none;
}
.ds_views a.active,.ds_views a.active strong, .ds_views a.active i{
  opacity: 1;
}
.mat-drawer-container,
.mat-drawer-content{
  z-index: auto !important;
}
.mat-drawer-content{
  overflow: hidden !important;
}
.ds_homePageBg{
    background: $bg-primary-80;
    margin: 0 -16px;
    padding: 32px 0;
}
.ds_docHeight{
    min-height: calc(100vh - 96px);
}
.ds_h1HomeHeader{
    font-size: 35px;
    font-weight: 500;
    margin: 0 0 24px;
}

.ds_h1Header{
  font-size:$size-hd-24;
  font-weight: $font-500;
}
.width-55{
    width: 55%;
    margin: 0 auto 32px;
}
.ds_copyRightTxt{
    line-height: 40px;
    color: $text-neutral-60;
}
.DSA_card-widget:hover .DSA_h4Header{
    color: $text-primary-80;    
}
.DSA_card-widget:hover{
    @extend %active-shadow;
    cursor: pointer;
}
.designView.active, .codeView.active{
  display: block;
}
.designView.hide, .codeView.hide{
  display: none;
}
@media screen and (max-width:1366px){
    .ds_fullWidthImg{
        max-width: 100%;       
    }

    .anatomy-wrap{
        .cr-image{
            width: 450px;
            img{width: 100%;}
        }
    }

    .hamburger-carousel-txt .cr-table> div{
        width: 50%;
        &.cr-image img{
            width: 100%;
        }
    }

    .systemMsgs-carousel .owl-prev{
        margin-right: 694px!important;

    }
  }
  .ds_docCustom-tab{

clear:both;
display: block !important;
  }
  .ds_docCustom-tab .mat-tab-label, .ds_docCustom-tab .mat-tab-link{
    font-size: $size-text-16;
    font-weight: $font-500;
    color:$text-black;
    opacity:1;
    min-width: 120px;
  }
  .ds_docCustom-tab.mat-tab-group.mat-primary .mat-ink-bar,
  .ds_docCustom-tab .mat-tab-nav-bar.mat-primary .mat-ink-bar{
    background-color:$bg-primary-80;
  }
  .ds_docCustom-tab .mat-ink-bar {
    height: 4px;    
  }
  .ds_docCustom-tab .mat-tab-label{
    padding:0;
    margin-right: 20px;
  }
  .ds_h2Header{
    font-size: $size-hd-18;
    font-weight: $font-400;
  }
  .ds_h3Header{
    font-size: $size-hd-16;
    font-weight: $font-400;
  }
.customTable{
    width: 100%;
    background: transparent;
    .mat-header-cell{
        font-size: $size-text-16;
        font-weight: $font-500;
        color: $text-black;
        border-bottom: 1px solid $bg-neutral-50;
    }
    td.mat-cell{
        border: none;
        color: $text-black;
    }
    td.mat-cell:first-of-type, td.mat-footer-cell:first-of-type, th.mat-header-cell:first-of-type{
        padding: 0;
    }
    .td-1{
        font-size: $size-text-16;
        font-weight: $font-500;
        text-transform: capitalize;
    }
    .td-2{
        font-size: $size-text-16;
        font-weight: $font-400;
    }
    .td-3{
        font-size: $size-text-16;
        font-weight: $font-500;
    }
    .td-4{
        font-size: $size-text-14;
        font-weight: $font-700;
        text-transform: uppercase;
    }
    .td-5{
        font-size: $size-text-14;
        font-weight: $font-500;
        text-transform: uppercase;
    }
    .td-6{
        font-size: $size-text-14;
        font-weight: $font-400;
        text-transform: capitalize;
    }
    .td-7{
        font-size: $size-text-12;
        font-weight: $font-400;
        text-transform: capitalize;
    }
    .td-8{
        font-size: $size-text-12;
        font-weight: $font-400;
    }
}
.txt-green{
    color: $text-status-success;
}


.ds_dataGrid{
  width: 100%;
  background: transparent;
}
ol.ds_numberOl,.ds_numberOl li{
  margin:0;
  padding: 0;
}
.ds_numberOl li{
  list-style: decimal;
  margin-left: 20px;
}
.ds_dataGrid tr.mainTh th{
  text-align: center;
  font-weight: 500;
  border-bottom: 1px solid $bg-neutral-50;
  padding-bottom: 24x;
  font-size: 20px;
}
.ds_dataGrid tr.topTh th{
  padding-bottom: 24x;
  border-bottom: 1px solid $bg-neutral-50;
}
   .ds_dataGrid th,.ds_dataGrid td{
      font-size: $size-text-16;
      color: $text-black;
      padding:16px 8px;  
      line-height: 18px; 
      vertical-align: top;  
  }
  .ds_dataGrid th{
      border: none;
      color: $text-black;
      font-weight: $font-500;
  }
  .ds_dataGrid td{
    border: none;
    color: $text-black;
    font-weight: $font-400;
    font-size:$size-text-14;
}
.messaging_dataGrid {
    tr th{
        width: 25%;
    }
    tr th:first-child{
        width: 35%;
    }
    tr th:last-child{
        width: 15%;
    }
    tr td{
        border-bottom: 1px solid $bg-neutral-50;
    }
    ol.ds_orderedlist{
        margin: 0;
        padding: 0;
        li{
            font-size: $size-text-14;
            margin: 0 0 8px 10px;
            span.txt-green{
                color: $text-status-success;
                display: inline;
                margin: 0;
            }
        }
    }
}

.ds_colwdth1{width:20%}
.ds_colwdth2{width:50%}
.ds_colwdth3{width:30%}

.ds_gridUL{
  line-height: 22px;
  margin-bottom: 5px;
  list-style: disc;
}
.ds_cauroselinnerTitle{
 font-size: 18px;
 font-weight: 500;
}

.ds_mainBodyDesc,.ds_mainBodyDescUL{
  font-size:$size-text-16;
  font-weight: $font-400;
  margin-bottom: 40px !important;
}
.ds_mainBodyDescUL{
  padding-left: 20px;
}
.ds_mainBodyDescUL li {
  font-size:$size-text-16;
  font-weight: $font-400;
  line-height: 30px;
  list-style: disc;
}

.ds_bullet-list {
  list-style-position: inside;
  margin-bottom: 30px;
  li{
    font-size:$size-text-14;
    font-weight: $font-400;
    position: relative;
    padding-left: 20px;
    margin-bottom: 10px;
    &:before{
      width: 5px;
      height: 5px;
      display:inline-block;
      background: $bg-black;
      border-radius: 50%;
      content: '';
      left:5px;
      top:6px;
      position: absolute;
    }
  }
}


.ds_mainBodyTxt3{
  font-size:$size-text-14;
  font-weight: $font-400;

}
.ds_gridUL{
  margin-left:18px;
}
.ds_gridUL li{
  list-style: disc;
}
.width-desc{
    width:900px;
    margin: 0 auto;
}

.ds_guidelineList{
  margin-bottom:40px;
  padding:40px 0px 80px 0px;
  border-bottom:1px solid $bg-neutral-50;
  position: relative;
}

.ds_guidelineList:last-child{
  border-bottom: 0;
  margin-bottom:0px;
  padding:40px 0px 0px 0px;
}
.ds_guidelineList:first-child{
  padding:20px 0px 40px 0px;
}



.ds_guidlinechip{
  background: $bg-white;
  height:40px;
  width:150px;
  text-align: center;
  position: absolute;
  left:40px;
  top:-20px;
  line-height: 40px;
  font-size: 16px;
  border:1px solid $bg-neutral-50;
  border-radius:30px;
}

.list-item{
    
    li{
     list-style: disc;
     list-style-position: inside;
   font-size: $size-text-16;
   margin-bottom: 10px;
    }
   }

.ds_le-wrap{
    padding: 40px 0;
}
.ds_link_teams{
  float: right;
  color: $text-neutral-60;
  background: url('/src/assets/images/teams-logo-grey.png') no-repeat right;
  line-height: 40px;
  padding: 0 40px 0 0;    
}
.ds_link_teams:hover, .ds_link_teams:focus{
  color: $text-white;
  background: url('/src/assets/images/teams-logo-white.png') no-repeat right;
  text-decoration: none;
}
.ds_dowloadList li span{
  line-height:30px;
  display: inline-block;
  margin-left: 5px;
}
.ds_scale{
    width: 100%;
}

.ds_fullWidthWrap{
width: 100%;
}
.example-wrap{
    padding: 10px;
    border: 1px solid $bg-neutral-50;
    border-radius: 4px;
    background: $bg-white;
}
.h4_example{
    font-size: 14px;
    margin: 0 0 8px;
}

.ds_tableList span{
  display: inline-block;
  margin-right: 30px;
  
}
.ds_dataGridAuto{
  width:auto;
}
@media screen and (max-width:650px){
  .ds_headerLftmar{
    margin-left: 16px;
  }
  .ds_views a strong{
    display: none;
  }
  a.ds_logo{
    font-size: 12px;
  }
  .ds_views a i{
    font-size: 20px;
  }
  .menu-icon.marT8{
    margin-top: 16px !important;
  }
  .ds_h2{
    font-size: 16px;
  }
}

.ds_minimize{
  position: fixed;
  right: 250px;
  top:10px;
  background-color: $bg-white;
  width:50px;
  height: 50px;
  border-radius: 50%;
  @extend %active-shadow;
  text-align: center;
  line-height: 30px;
  z-index: 999999;
  color:$text-neutral-80;
  padding-top:12px;
  font-size: 28px;
  display: none;
}

