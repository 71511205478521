/*Button styles */
@mixin btn-styles{    
    font-size: $text-md;
    font-weight: 500;
    font-style: normal;
    text-transform: uppercase;
    font-family: $base-font;    
    border: 1px solid transparent;
    padding:8px 16px !important;
    line-height: 16px !important;
    background: none
}

@mixin btn-styles-web{    
    font-size: $size-button-18;
    font-weight: 600;
    font-style: normal;
    text-transform: capitalize;
    font-family: $font-style-2;    
    border: 1px solid transparent;
    padding:11px 40px 10px!important;
    line-height: 16px !important;
    background: none;
}

@mixin innerBtn-styles-web{    
    font-size: $size-button-14;
    font-weight: 600;
    font-style: normal;
    text-transform: capitalize;
    font-family: $font-style-2;    
    border: 1px solid transparent;
    padding:8px !important;
    line-height: 14px !important;
    background: none;
    border-radius: 16px !important;
}

@mixin miniBtn-styles-web {
    font-size: $size-button-14;
    font-weight: 600;
    font-style: normal;
    font-family: $font-style-2;  
    padding:5px 28px 5px!important;
    line-height: 14px !important;
    background: none;
    border-radius:4px !important;
}

/* card styles*/
@mixin card-styles{
    display: block;
    position: relative;
    padding: 16px;
    border-radius: 4px;
}

/**/
@mixin verticalCenter{
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
/* icon circle*/
@mixin icon-circle{
    width:40px;
    height:40px;
    display: inline-block;
    border-radius:50%;
    text-align: center;
    vertical-align:middle;
    padding-top: 8px;
    background-repeat: no-repeat !Important;
    background-position: 50% 50% !Important;
}
@mixin icon-circle-large{
    width:56px;
    height:56px;
    display: inline-block;
    border-radius:50%;
    text-align: center;
    vertical-align:middle;    
    background-repeat: no-repeat !Important;
    background-position: 50% 50% !Important;
}
@mixin icon-circle-small{
    width:24px;
    height:24px;
    display: inline-block;
    border-radius:50%;
    text-align: center;
    vertical-align:middle;
    padding-top: 4px;
    background-repeat: no-repeat !Important;
    background-position: 50% 50% !Important;
}

/* Transition */
@mixin transition($what: all, $time: 0.2s, $how: ease-in-out) {
    -webkit-transition: $what $time $how;
    -moz-transition:    $what $time $how;
    -ms-transition:     $what $time $how;
    -o-transition:      $what $time $how;
    transition:         $what $time $how;
}

/* Transform */
@mixin transform($transforms) {
	   -moz-transform: $transforms;
	     -o-transform: $transforms;
	    -ms-transform: $transforms;
	-webkit-transform: $transforms;
          transform: $transforms;
}
/* rotate */
@mixin rotate ($deg) {
  @include transform(rotate(#{$deg}deg));
}
 
/* scale */
@mixin scale($scale) {
	 @include transform(scale($scale));
} 
/* translate */
@mixin translate ($x, $y) {
   @include transform(translate($x, $y));
}
/* skew */
@mixin skew ($x, $y) {
   @include transform(skew(#{$x}deg, #{$y}deg));
}
/* transform origin */
@mixin transform-origin ($origin) {
    moz-transform-origin: $origin;
	     -o-transform-origin: $origin;
	    -ms-transform-origin: $origin;
	-webkit-transform-origin: $origin;
          transform-origin: $origin;
}


@mixin default-shadow{
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.16) !important;
}
@mixin active-shadow{
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.24) !important;
}
@mixin hover-shadow{
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.32) !important;
}
@mixin footer-shadow{
    -webkit-box-shadow: 0px -3px 2px 0px rgba(204,204,204,0.1);
    -moz-box-shadow: 0px -3px 2px 0px rgba(204,204,204,0.1);
    box-shadow: 0px -3px 2px 0px rgba(204,204,204,0.1);
}
@mixin appmenu-shadow{
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.15) !important;
}
@mixin leftnav-shadow{
    box-shadow: 1px -1px 4px 0px rgba(0, 0, 0, 0.16) !important;
}
@mixin notification-shadow{
    box-shadow: -5px 0 25px -5px rgba(0,0,0,.75)!important;
}
@mixin rightpanel-shadow{
    box-shadow: -1px -1px 4px 0px rgba(0, 0, 0, 0.16) !important;
}
@mixin header-shadow{
    box-shadow: 0px 5px 5px -2px rgba(0,0,0,0.3) !important;
}
@mixin accordion-shadow{
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08) !important;
}
