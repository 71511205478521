//
// variables.scss
//

/* Heading or Title font sizes */
$heading-lg: 3.2rem; //32px
$heading-md: 2.4rem; //24px
$heading-sm: 2rem; //20px

/* Body text font sizes */
$text-lg: 1.6rem; //16px
$text-md: 1.4rem; //14px
$text-sm: 1.2rem; //12px
$text-xs: 1rem; //10px

$text-body: 62.5%;

/* Body text font weight */
$text-bold: 700;
$text-medium: 500;
$text-regular: 400;

/* Theme bg color */
$bgcolor-thick: #007c64;
$bgcolor-dark: #00b28f;
/*$bgcolor-lighter:#AA59DD;*/
$bgcolor-lightest: #e5f7f3;

/* Secondary bg color */
$bgcolor-green: #dff6dd;
$bgcolor-red: #fde7e9;
$bgcolor-blue: #deebff;
/*$bgcolor-orange: #F7941E;*/
$bgcolor-orange: #fff4ce;
$bgcolor-black: #030303;
$bgcolor-white: #ffffff;

/* Grey bg color */
$bggrey-dark: #212121;
$bggrey-light: #737373;
$bggrey-lighter: #dadada;
$bggrey-lightest: #efefef;
$bggrey-lightwhite: #fafafa;

/* Theme text color */
$color-thick: #007c64;
$color-dark: #00b28f;
/*$color-lighter:#AA59DD;*/
$color-lightest: #e5f7f3;

/* Secondary text color */
$green-color: #0b8043;
$red-color: #b71c1c;
$blue-color: #3367d6;
$orange-color: #f7941e;
$black-color: #000000;
$white-color: #ffffff;

/* Grey text color */
$grey-dark: #212121;
$grey-light: #737373;
$grey-lighter: #dadada;
$grey-lightest: #efefef;
$grey-lightwhite: #fafafa;

/* Font declaration */
$base-font: "myriad-pro", "roboto", Arial, sans-serif !important;

/* Extend/Inheritance box shadows */
%mb-flat-shadow {
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08) !important;
}
%mb-default-shadow {
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16) !important;
}
%mb-raised-shadow {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.24) !important;
}
%mb-active-shadow {
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.24) !important;
}
%mb-rght-shadow {
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.15) !important;
}
%mb-rght-shadow2 {
  box-shadow: 2px -1px 2px rgba(0, 0, 0, 0.15) !important;
}

/* Web Heading or Title font sizes */

$basefont-type: "myriad-pro", "roboto", Arial, sans-serif !important;
$font-style-1: "myriad-pro", "roboto", Arial, sans-serif !important;
$font-style-2: "Oswald", "myriad-pro", Arial, sans-serif !important;

$basefont-size: 62.5%;
$baseBodyfont-size: 1.6rem;

$size-hd-48: 4.8rem; //48px

$size-hd-24: 2.4rem; //24px
$size-hd-18: 1.8rem; //18px

$size-hd-16: 1.6rem; //16px
$size-hd-14: 1.4rem; //14px
$size-hd-12: 1.2rem; //12px
$size-hd-10: 1rem; //10px

// heading sizes
$size-h1: 40px;
$size-h2: 32px;
$size-h3: 24px;
$size-h4: 22px;
$size-h5: 18px;

// heading line heights
$line-height-1: 56px;
$line-height-2: 40px;
$line-height-3: 20px;
$line-height-4: 20px;
$line-height-5: 27px;


/* Web body text font sizes */
$size-text-16: 1.6rem; //16px
$size-text-14: 1.4rem; //14px
$size-text-12: 1.2rem; //12px

$size-text-10: 1rem; //10px

/* Web button text font sizes */
$size-button-18: 1.8rem; //18px
$size-button-16: 1.6rem; //16px
$size-button-14: 1.4rem; //14px
$size-button-12: 1.2rem; //12px

/* Web body text font weight */
$font-700: 700;
$font-500: 500;
$font-400: 400;
$font-600: 600;

/*lineheight*/
$line-height-10: 1rem;
$line-height-12: 1.2rem;
$line-height-14: 1.4rem;
$line-height-16: 1.6rem;
$line-height-18: 1.8rem;
$line-height-24: 2.4rem;
$line-height-48: 4.8rem;

/*Web theme bg color*/
$bg-primary-100: #007c64;
// $bg-primary-80:#8626C3;//temporarily commenting to match the theme
$bg-primary-80: #00b28f;
/*$bg-primary-50er:#AA59DD;*/
$bg-primary-60: #ccefe8;
$bg-primary-50: #e5f7f3;

$bg-secondary: #df9926;

/* Web grey bg color */
$bg-neutral-90: #555555;
$bg-neutral-80: #737373;
/* $bg-neutral-60:#B8B8B8;  */
$bg-neutral-60: #999999; // As per new Design
$bg-neutral-50: #dadada;
$bg-neutral-40: #efefef;
$bg-neutral-30: #fafafa;

/* Web secondary bg color */
$bg-status-success: #dff6dd;
$bg-status-error: #fde7e9;
$bg-status-informtion: #deebff;
$bg-status-warning: #fff4ce;

$bg-black: #000000;
$bg-white: #ffffff;

/* Web secondary Bg color */
/*$wb-bg-green-color:#DFF6DD;
$wb-bg-red-color:#FDE7E9;
$wb-bg-blue-color:#DEEBFF;
$wb-bg-orange-color:#FFF4CE ;*/

/* Web theme text color */
$text-primary-100: #007c64;
// $text-primary-80:#8626C3;
// $text-primary-80:#555555;
// $text-primary-80: #428bca;
$text-primary-80: #00b28f;
// $text-primary-80:#8626C3;
/*$wb-color-lighter:#AA59DD;*/
/*$wb-color-lightest:#EDCFFF;
$wb-color-light: #FAF2FF; */

/* Web secondary text color */
$text-status-success: #0b8043;
$text-status-error: #b71c1c;
$text-status-informtion: #3367d6;
$text-status-warning: #f7941e;

/*$wb-lightorange-color:#FFCA28;*/

$text-black: #000000;
$text-white: #ffffff;

/* Web grey text color */
$text-neutral-90: #555555;
$text-neutral-80: #7e7e7e;
/* $text-neutral-60:#B8B8B8; */
$text-neutral-60: #999999; // As per new Design
$text-neutral-50: #dadada;
/*$text-neutral-80est:#EFEFEF;
$text-neutral-80white:#FAFAFA;*/

/* Web icon color */
$icon-primary-100: #5e1f89;
// $icon-primary-80:#8626C3;
$icon-primary-80: #df9926;
$icon-status-success: #0b8043;
$icon-status-error: #b71c1c;
$icon-status-informtion: #3367d6;
$icon-status-warning: #ff8800;
/*$wb-iconcolor-lightOrange:#FFCA28;*/
$icon-black: #000000;
$icon-white: #ffffff;
$icon-yellow: #ffca28;

$icon-neutral-80: #737373;
$icon-neutral-60: #dadada;

/*$wb-icongrey-midlight:#B8B8B8;
$icon-neutral-80er:#DADADA;
$icon-neutral-80est:#EFEFEF;
$icon-neutral-80white:#FAFAFA;*/

/*border color*/

$border-neutral-40: #efefef;

$border-radius-16: 16px;

/* border radius*/
$border-radius-none: 0px;
$border-radius-big: 8px;
$border-radius-extrabig: 16px;
$border-radius-small: 4px;
$border-radius-verysmall: 2px;
$border-radius-circle: 50%;
$border-radius-large: 20px;

$border-radius-date: $border-radius-small;
$border-radius-dateCell: $border-radius-small;

/* border widths*/
$border-width-1: 1px;
$border-width-2: 2px;
$border-width-4: 4px;

/*border color*/
$border-neutral-40: #efefef;
$border-neutral-50: #dadada;
$border-neutral-60: #b8b8b8;
$border-neutral-80: #737373;
$border-primary-60: #edcfff;
$border-primary-80: #00b28f;
$border-primary-100: #007c64;
$border-black: #000000;
$border-white: #ffffff;
$border-status-error: #b71c1c;
$border-status-informtion: #deebff;
$border-status-success: #dff6dd;
$border-chip: #0b8043;

/* %flat-shadow{
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.08) !important;
}
%flat-shadow-01{
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.08) !important;
} 
%raised-shadow{
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.16) !important;
}
%default-shadow-01{
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.16) !important;
}
%carouselIcon-shadow{
    box-shadow: -8px 0px 16px 0px rgba(0, 0, 0, 0.50) !important;
}
%column-shadow{
    box-shadow: 1px 0px 2px 0px rgba(0, 0, 0, 0.16) !important;
}*/
%default-shadow {
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.16) !important;
}
%active-shadow {
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.24) !important;
}
%hover-shadow {
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.32) !important;
}
%footer-shadow {
  -webkit-box-shadow: 0px -3px 2px 0px rgba(204, 204, 204, 0.1);
  -moz-box-shadow: 0px -3px 2px 0px rgba(204, 204, 204, 0.1);
  box-shadow: 0px -3px 2px 0px rgba(204, 204, 204, 0.1);
}
%appmenu-shadow {
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.15) !important;
}
%leftnav-shadow {
  box-shadow: 1px -1px 4px 0px rgba(0, 0, 0, 0.16) !important;
}
%notification-shadow {
  box-shadow: -5px 0 25px -5px rgba(0, 0, 0, 0.75) !important;
}
%rightpanel-shadow {
  box-shadow: -1px -1px 4px 0px rgba(0, 0, 0, 0.16) !important;
}
%header-shadow {
  box-shadow: 0px 5px 5px -2px rgba(0, 0, 0, 0.3) !important;
}
%accordion-shadow {
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.016) !important;
}

%accordion-hover-shadow {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.24) !important;
}

%accordion-pressed-shadow {
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16) !important;
}
